import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ProgramadorGuard } from "./global/guards/programador.guard";
import { LoginComponent } from "./pages/login/login.component";
import { ProgramadorComponent } from "./pages/programador/programador.component";
import { SelectorPlantaComponent } from "./pages/selector-planta/selector-planta.component";
import { AdministradorGuard } from "./global/guards/administrador.guard";

const routes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "", component: LoginComponent },
  {
    path: "programador",
    component: ProgramadorComponent,
    canActivate: [ProgramadorGuard],
  },
  { path: "selector", component: SelectorPlantaComponent },
  {
    path: "administrador",
    loadChildren: () =>
      import("./pages/administrador/administrador.module").then(
        (m) => m.AdministradorModule
      ),
    canActivate: [AdministradorGuard],
    canLoad: [AdministradorGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
